<template>
  <div class="graph-wrapper">
    <div class="bonus-graph">
      <div
          :class="segmentClass"
          :style="{ width: segmentWidth + '%' }"
          @mouseover="showLegendAction(true)"
          @mouseleave="showLegendAction(false)"
      >
        <div class="legend">
          <div v-if="showLegend" class="tooltip-container">
            Общая сумма всех выполненных заказов за месяц
          </div> 
         <span>{{ props.params.title }}</span><span>{{ formatter.format(props.params.sales) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'

const props = defineProps(['params', 'cashback'])
const showLegend = ref(false) // Управление видимостью тултипа

const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})

// Определение класса сегмента на основе cashback и sales
const segmentClass = computed(() => {
  if (props.cashback === 20) {
    if (props.params.sales <= 20_000) {
      return 'bonus-graph__segment--red'
    } else if (props.params.sales <= 150_000) {
      return 'bonus-graph__segment--light-green'
    } else {
      return 'bonus-graph__segment--dark-green'
    }
  } else {
    if (props.params.sales <= 20_000) {
      return 'bonus-graph__segment--red'
    } else {
      return 'bonus-graph__segment--dark-green'
    }
  }
})

// Вычисление ширины сегмента
const segmentWidth = computed(() => {
  return 100
})

// Функция для управления видимостью тултипа
const showLegendAction = (status) => {
  showLegend.value = status
}
</script>

<style lang="scss" scoped>
.graph-wrapper {
  width: 100%;
    text-align: -webkit-center;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-end;
  .bonus-graph {
    width: 100%;
    height: 25px;
    background: #cecece;
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;

    &__segment {
      height: 100%;
      border-radius: 20px;
      &--black {
        cursor: pointer;
        border-radius: 30px;
        background: #fd5f5f;
      }
      &--red {
        cursor: pointer;
        border-radius: 30px;
        background: #fd5f5f;
      }
      &--light-green {
        cursor: pointer;
        border-radius: 30px;
        background: #ffc04c;
      }
      &--dark-green {
        cursor: pointer;
        border-radius: 30px;
        background: #00c266;
      }
    }
  }

  .title {
    font-size: 12px;
    color: #8f8f8f;
    padding-bottom: 5px;
  }
}

.legend {
  position: relative;
    width: 100%;
    height: 24px;
    font-size: 13px;
    color: white;
    align-content: center;
    font-weight: 700;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.tooltip-container {
  position: absolute;
  top: -70px;
  left: 50%;
  width: 200px;
  height: auto;

  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 10;
}
</style>
