<template>
  <div class="сol-12">
    <div class="doctors-wrapper container-doctor">
      <div class="h4 message-no-analysis container-message" v-if="!doctorsStore.isLoading && !doctorsStore.doctors" >Врачи не найдены
      </div>
      <template v-if="doctorsStore.doctors">
        <div class="size-search">
          <input
              type="text"
              class="form-control mb-3 mobile-search-input search-analysis"
              placeholder="Поиск врачей"
              v-model="searchQuery"
          />
        </div>
        <div
            class="text-center"
            v-if="
          searchQuery !== '' &&
          !filteredDoctor.length
        "
        >
          <h2 class="text-uppercase h4 message-no-analysis">
            Доктор не найден
          </h2>
        </div>
        <div class="card w-100" v-for="(doctor, index) in filteredDoctor" :key="doctor.id"
           :class="{'last-card': index === doctorsStore.doctors.length - 1}">
        <div class="card-body p-4 p-sm-3">
          <div class="row">
            <div class="col-12 col-sm-12 col-lg-4">
              <h4 class="card-title d-flex align-items-center">
                {{ doctor.last_name }} {{ doctor.first_name }} {{ doctor.middle_name }}
                <ActiveStatus :type="doctor.active" class="user-select-none"/>
              </h4>
              <p class="card-text mb-1"><b>Последний онлайн:</b> {{ doctor.online_at ?? 'Нет данных' }}</p>
              <p class="card-text mb-1"><b>Почта:</b> {{ doctor.email ?? 'Не заполнен' }}</p>
              <p class="card-text mb-1"><b>Телефон:</b> {{ doctor.phone ?? 'Не заполнен' }}</p>

              <div class="mt-3 row row-cols-2 row-cols-sm-1 g-1 buttons-wrapper">

                <div v-if="!authStore.userProfile.role === 'admin'" class="col">
                  <button type="button"
                          class="m-0 btn btn-sm btn-primary"
                          @click="setDoctorData(doctor); showEditDoctorModal()"
                  >
                    <i class="bi bi-pencil-square"/>
                    Редактировать
                  </button>
                </div>
                <div class="col">
                  <button type="button"
                          class="m-0 btn btn-sm btn-secondary position-relative"
                          :class="{'btn-secondary': doctor.active, 'btn-success': !doctor.active , 'pe-none': doctorsStore.isLoading && activeButtonId === doctor.id}"
                          @click="doctorsStore.disableDoctor(doctor.id); activeButtonId = doctor.id"

                  >
                    <i :class="doctor.active ? 'bi bi-shield-x' : 'bi bi-shield-plus'"/>
                    {{ doctor.active ? 'Заблокировать' : 'Разблокировать' }}

                    <span v-if="doctorsStore.isLoading && activeButtonId === doctor.id"
                          class="placeholder-glow placeholder-surface">
                                        <span class="placeholder"/>
                                    </span>
                  </button>
                  <button type="button"
                          class="m-0 btn btn-sm btn-danger"
                          @click="setDoctorData(doctor); showDeleteDoctorModal()"
                  >
                    <i class="bi bi-x-circle"/>
                    Удалить
                  </button>
                  <button v-if="doctor.email"
                          type="button"
                          class="m-0 btn btn-sm btn-outline-danger position-relative"
                          @click="usersStore.getPasswordResetUrl(doctor.email, doctor.id)"
                  >
                    <i class="bi bi-arrow-clockwise"></i>
                    Сбросить пароль

                    <span v-if="usersStore.passwordResetLoading[doctor.id]"
                          class="placeholder-glow placeholder-surface">
                                    <span class="placeholder"/>
                                </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-11 col-lg-8 mt-3 align-content-center">
              <DoctorBonusGraph v-if="doctorsStore.doctorBonus?.find((item) => item.doctor_id === doctor.doctor_id) && !isLoading && (doctorsStore.doctorBonus?.find((item) => item.doctor_id === doctor.doctor_id))?.data" :data="doctorsStore.doctorBonus.find((item) => item.doctor_id === doctor.doctor_id).data" :main-chart-title="`mainChart_${doctor.id}`"></DoctorBonusGraph>
              <div v-else class="message-container">
                <p v-if="!doctorsStore.isLoading">Данные по бонусам формируются...</p>
              </div>
              <div v-if="doctorsStore.isLoading">
                <LoadingSpinner :smallSpinner="`smallSpinner`"  :wrapperStyle="`width: 100%;
    height: 1px;
    justify-content: center;
    position: relative;
    display: contents;`" :innerStyle="`position: relative;
    left: 50%;
    top: 20px;
    transform: translate(-50%, -50%);`"  :showSpinner="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      </template>
      <div v-if="doctorsStore.isLoading && !doctorsStore.doctors">
        <LoadingSpinner wrapperStyle="top: 15%" :showSpinner="true" spinnerStyle="z-index:1; top: 10%;"/>
      </div>
    </div>
  </div>

  <teleport to="body">
    <DeleteDoctorModal :doctorDetails="doctorDetails"/>
    <EditDoctorModal :doctorDetails="doctorDetails"/>
  </teleport>
</template>

<script setup>
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import ActiveStatus from '@/components/Doctors/ActiveStatus.vue'
import { useDoctorsStore } from '@/stores/DoctorsStore'
import { computed, onMounted, provide, ref } from 'vue'
import { useUsersStore } from '@/stores/UsersStore'
import EditDoctorModal from '@/components/Doctors/Modals/EditDoctorModal.vue'
import DeleteDoctorModal from '@/components/Doctors/Modals/DeleteDoctorModal.vue'
import { useAuthStore } from '@/stores/AuthStore'
import DoctorBonusGraph from '@/components/ui/DoctorBonusGraph.vue'

const authStore = useAuthStore()
const doctorsStore = useDoctorsStore()
const usersStore = useUsersStore()

const activeButtonId = ref(null)
const doctorDetails = ref(null)
const deleteDoctorModal = ref(null)
const editDoctorModal = ref(null)
const searchQuery = ref('')
provide('deleteDoctorModal', deleteDoctorModal)
provide('editDoctorModal', editDoctorModal)

const setDoctorData = (doctorData) => doctorDetails.value = doctorData
const showDeleteDoctorModal = () => deleteDoctorModal.value.show()

const showEditDoctorModal = () => {
  editDoctorModal.value.show()
  activeButtonId.value = null
}

const filteredDoctor = computed(() => {
  const searchTerms = searchQuery.value.toLowerCase().split(' ')
  if (searchQuery.value === '') {
    return doctorsStore.doctors
  } else {
    return doctorsStore.doctors.filter((value) => {
      return searchTerms.every((term) => {
        return (
            value.last_name.toLowerCase().includes(term) || (value.first_name.toLowerCase().includes(term) + value.last_name.toLowerCase().includes(term)) ||
            (value.middle_name
            ? (value.first_name.toLowerCase().includes(term) + value.last_name.toLowerCase().includes(term) + value.middle_name.toLowerCase().includes(term))
            : '') || (value.email ? value.email.includes(term) : '') || (value.phone ? value.phone.includes(term) : '')
        )
      })
    })
  }
})

onMounted(() => {
  doctorsStore.getDoctors().finally(() => {
    if (doctorsStore.doctors) {
      doctorsStore.getDoctorBonus(doctorsStore.doctors.map(doctor => doctor.doctor_id))
    }
  })
})

</script>

<style lang="scss" scoped>
.ps {
  max-height: var(--ps-height);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);

  .card {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    &.last-card {
      border-bottom: 1px solid transparent;
    }

    .buttons-wrapper {
      @media (min-width: 576px) {
        flex-wrap: nowrap;
      }

      .col {
        @media (min-width: 576px) {
          width: fit-content;
          flex-shrink: 1;
        }

        button {
          @media (max-width: 575.98px) {
            width: 100%;
          }

          //Минимальная ширина для текста кнопок в одну линию
          @media (max-width: 390px) {
            font-size: calc(var(--bs-btn-font-size) * 0.9);
          }
        }
      }
    }

  }
}

.col {
  flex: 1 0 0%;
  gap: 10px;
  display: flex;
}

.container-doctor {
  margin-bottom: 50px;
}
.text-center {
  text-align: center !important;
  height: 300px
}
.message-no-analysis {
  height: 50%;
  color: #aeaeae;
  font-size: larger;
  align-content: end;
}
.container-message{
  width: 100%;
  height: calc(-333px + 100vh);
  align-content: center;
  text-align: center;
}

.message-container {
  color: #7d7b7b;
  font-size: 14px;

}
</style>
