<template>
  <div class="doctor-bonus-graph">
    <div class="row" style="justify-content: center">
      <div class="col-12 col-sm-7 container-graph row">
      <div class="col-xs-6 col-sm-12 col-md-7 current-month-graph current-month-graph">
        <CurrentMonthChart    
        v-if="props.data?.currentMonth?.threshold" 
            class="col-9"
            :title="'Текущий месяц'"
            :sold="props.data?.currentMonth?.sales"
            :threshold="props.data?.currentMonth.threshold"
            :total="150000"
            :cashback="props.data?.cacheBack"
            :chart-class="mainChartTitle"
        />
        <div class="col-12  dinamic-month-percent">
         <span> *</span> Процент вознаграждения
    </div>
      </div>
      <div class="col-12 col-sm-12 col-md-5 container-price">
        <span class="size-lable">Цель, руб</span>
        <span class="size-price"
        ><span class="dop-price">{{
            props.data?.cacheBack < 20 ? '20 000' : '150 000'
          }}</span></span
        >
        <span class="size-lable">Факт, руб</span>
        <span class="size-price"
        ><span class="dop-price">{{
            formatNumber(props.data?.currentMonth?.sales)
          }}</span></span
        >

        <span class="size-lable">Баллов к выплате, руб</span>
        <span class="size-price"
        ><span class="dop-price">{{
            formatNumber(props.data?.bonus)
          }}</span></span
        >
      </div>
    </div>
    <div class="col-8 col-md-4 container-dynamics mt-3 mt-md-0">
      <div class="col-12 text-center mb-0 dinamic-month mt-0">
    Динамика предыдущих месяцев
    </div>
    <div  v-if="props.data?.beforeMonths" class="month-graphics">
      <div class="month-graphics__item">
        <BonusGraph
            :params="props.data?.beforeMonths[0]"
            :full="true"
            :cashback="props.data?.cacheBack"
        ></BonusGraph>
       </div>
     <div class="month-graphics__item">
        <BonusGraph
            :params="props.data?.beforeMonths[1]"
            :full="true"
            :cashback="props.data?.cacheBack"
        ></BonusGraph>
       </div> 
      <div class="month-graphics__item"> 
        <BonusGraph
            :params="props.data?.beforeMonths[2]"
            :full="true"
            :cashback="props.data?.cacheBack"
        ></BonusGraph>
       </div> 
       <div class="col-12  dinamic-sale-percent">
        Выплаченно баллов, руб <span>{{
            formatNumber(props.data?.bonus)
          }}</span>
    </div>
    </div>
</div>
<div class="col-xs-12 col-10">
      <span class="text-label-segment"><span class="red-square"></span>  Деятельность до 20 000 руб. </span>
      <span v-if="props.data?.cacheBack >= 20" class="text-label-segment"><span class="yellow-square"></span> Деятельность до 150 000 руб. </span>
      <span v-if="props.data?.cacheBack >= 20" class="text-label-segment"><span class="green-square"></span> Деятельность свыше 150 000 руб. </span>
      <span v-if="props.data?.cacheBack < 20" class="text-label-segment"><span class="green-square"></span> Деятельность свыше 20 000 руб. </span>
    </div>
</div>

  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import BonusGraph from '@/components/ui/BonusGraph.vue'
import CurrentMonthChart from '@/components/ui/СurrentMonthChart.vue'

const props = defineProps(['data', 'mainChartTitle'])

const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})
const formatNumber = (num) => {
  if (num === 0) {
    return 0
  }
  if (num) {
    return num.toLocaleString('ru-RU')
  }
}
</script>

<style lang="scss" scoped>
.text-label-segment{
  font-size: 10px;
    padding-left: 10px;
    font-weight: bold;
    color: #91a36a;
    position: relative;
    position: relative;
    left: 5%;
    height: 20px;
    display: inline-flex;
    .red-square{
      margin-right:10px;
      width: 20px;
    height: 20px;
    display: inline-block;
    /* color: red; */
    background: #fd5f5f;
    border-radius: 50%;
    }
    .yellow-square{
      margin-right:10px;
      width: 20px;
    height: 20px;
    display: inline-block;
    /* color: red; */
    background: #ffc04c;
    border-radius: 50%;
    }
    .green-square{
      margin-right:10px;
      width: 20px;
    height: 20px;
    display: inline-block;
    /* color: red; */
    background: #00c266;
    border-radius: 50%;
    }
}
.doctor-bonus-graph {
  width: 100%;
  height: auto;

  &__title {
    color: #333;
    font-size: 14px;
  }

  .month-graphics {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: nowrap;
    flex-direction: column;
    grid-gap: 30px;
    margin-top: 20px;
    @media screen and (max-width: 575px) {
      margin: 0 auto 0;
      text-align: -webkit-center;
    }
    .month-graphics__item {
      width: 50%;
      @media screen and (max-width: 1200px) {
        margin-bottom: 10px;
        width: 100%;
      }
    }
    @media screen and (max-width: 1200px) {
      display: block;
      width: 100%;
    }
  }

  .bonus-graph-text {
    padding-top: 15px;
    font-size: 14px;
    color: #333;
  }
}
.container-graph {
  display: flex;
    margin-bottom: 40px;
    background: rgb(233,233,233);
background: linear-gradient(187deg, #f2f2f2 0%, rgba(255,255,255,1) 100%);
  @media screen and (max-width: 575px) {
    margin-bottom: 10px;
  }
}

.container-lable {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.size-lable {
  color: #00c266;
    width: 150px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    @media (min-width: 1000px) and (max-width: 1200px) {
    font-size: 14px;
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 576px) and (max-width: 10px) {
    font-size: 13px;
        width: 100%;
        font-weight: bold;
  }
}
.size-price {
  width: 210px;
    font-size: x-large;
    font-weight: 700;
    width: 150px;
    display: flex;
    justify-content: flex-end;

  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 700px) and (max-width: 1200px) {
    box-sizing: content-box;
    justify-content: center;
    font-size: x-large;
    width: 100%;
  }
}
.dop-price {
  width: 100px;
    text-align: end;
    margin-bottom: 5px;
    @media screen and (max-width: 700px) {
    text-align: center;
    }
}
.container-price {
  top: 30px;
    font-size: 13px;
    position: relative;
    line-height: 25px;
  @media screen and (max-width: 1200px) {
    line-height: normal;
    margin-top: 1rem !important;
    left: 0;
    text-align: center;
  }
  @media screen and (max-width: 575px) {
    margin-top: 0rem !important;
    top: 10px;
  }
}
.dinamic-month {
  font-size: 14px;
    color: #000000;
    margin: 0;
    padding-bottom: 5px;
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}
.current-month-graph {
  text-align: center;
  @media screen and (max-width: 575px) {
    text-align: -webkit-center;
  }
  @media screen and (min-width: 575px) and (max-width: 1100px) {
    text-align: -webkit-center;
  }
}
.title-container {
  font-size: 14px;
  color: #000000;
  margin: 0;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 5px;
  @media screen and (min-width: 1200px) {
    position: relative;
        left: 115px;
        display: flex;
  }
}
.dinamic-sale-percent{
  font-size: 13px;
  color: #000;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 5px;
  font-weight: 700;
  top: 13px;
  color: #00c266;
  position: relative;
  justify-content: flex-end;
  display: flex;
  align-items: center;
    @media screen and (max-width: 575px) {
      padding-left: 0;
    }
  span{
    top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: red;
    margin-left: 10px;
  }
}
.dinamic-month-percent{
  font-size: 11px;
  color: #000000;
  margin: 0;
  padding-left: 10px;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 5px;
  
  span{
    font-size: 24px;
    font-weight: bold;
  }
}
.container-dynamics{
  background: rgb(233,233,233);
  background: linear-gradient(187deg, #f2f2f2 0%, rgba(255,255,255,1) 100%);
  margin-bottom: 40px;
  margin-left: 25px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
}
</style>
